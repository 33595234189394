import { Message, MessageBox } from "element-ui";
import {SERVER_ERROR_MESSAGE} from "@/utils/messageUtils";

export const GlobalOpenImage = url => {
  // Membuka URL dalam tab baru
  window.open(url, "_blank");
};

export const GlobalHandleErrorApi = (errorMessage, returnType = "array") => {
  if (errorMessage) {
    let sequence = 1;
    let message = [];
    let messageString = [];

    for (const [key, value] of Object.entries(errorMessage)) {
      let alertTemp = {
        index: sequence,
        key: key,
        value: returnType == "object" ? value : value[0]
      };
      message.push(alertTemp);
      messageString.push(value[0]);
    }
    if (returnType == "string") {
      return messageString.join();
    }
    return message;
  }
  return "Opps! Ada kesalahan";
};

// global function message! hati2 untuk merubah pesan disini karena sudah dipakai ini
export const HandlerNotification = (jenis = null, message = null) => {
  if (!jenis) {
    Message({
      type: "success",
      message: message || "Berhasil Diproses!"
    });
    return;
  }
  if (jenis == "id") {
    Message({
      type: "error",
      message:
        message ||
        "ID tidak ditemukan, Ulangi Lagi dari awal dan Refresh Halaman"
    });
    return;
  }
  if (jenis == "permanen") {
    Message({
      type: "error",
      message:
        message ||
        "Data sudah digunakan. Untuk proses hapus secara permanen tidak di perbolehkan."
    });
    return;
  }
  if (jenis == "notAkses") {
    Message({
      type: "info",
      message:
        message ||
        "Produk Berlangganan Tidak Aktif / Level anda tidak ada Akses untuk melakukan perintah ini!"
    });
    return;
  }
  if (jenis == "error") {
    Message({
      type: "error",
      message: message || "Jaringan Bermasalah!"
    });
  } else if (jenis == "info") {
    Message({
      type: "info",
      message: message || "Informasi NULL!"
    });
  } else if (jenis == "cors") {
    Message({
      type: "error",
      message: message || "Cors Error, Hubungi Admin/Tunggu Beberapa Saat lagi"
    });
  } else {
    Message({
      type: "error",
      message: message || "Server Error"
    });
  }
};
export const HandlerResult = (result = null, message = null) => {
  if (result.data == "") {
    Message({
      type: "error",
      message: `Response ${message} Tidak Ditemukan. Ini disebabkan ketika mengambil data ke server, server tidak mengirim response apapun.`
    });
    return false;
  }
};
export const HandlerPageStartEnd = (
  currentpage = 1,
  dataTotal = 0,
  limit = 10
) => {
  if (currentpage == 1) return 1;
  if (!limit) return 1;
  if (!dataTotal || dataTotal == 1) return currentpage - 1;
  return currentpage;
};
export const HandlerAlertUnauthorized = () => {
  MessageBox.alert(
    `Login sudah <strong>kadalwarsa</strong> atau <strong>Akun ini sudah di non akatifkan</strong>. Anda akan diarahkan ke halaman LOGIN.`,
    "PEMBERITAHUAN",
    {
      closeOnClickModal: true,
      closeOnPressEscape: true,
      confirmButtonText: "OK",
      dangerouslyUseHTMLString: true,
      callback: function() {
        localStorage.removeItem("storeonklas");
        window.location = "/login";
      }
    }
  );
  return true;
};

export const HandlerMessageServerError = () => {
	MessageBox.alert(
		SERVER_ERROR_MESSAGE,
		"PEMBERITAHUAN",
		{
			closeOnClickModal: true,
			closeOnPressEscape: true,
			confirmButtonText: "OK",
			dangerouslyUseHTMLString: true,
		}
	);
}
