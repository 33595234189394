import repository from "@/repositories/GlobalRepository";
import ServiceError from "@/services/ServiceError";

export default class GlobalService {
	constructor() {
		this.dataRepository = repository;
	}

	async presenceSummaryGet(payload) {
		const { role, filter, start_date, end_date } = payload;
		if (!role && !["teacher", "student"].includes(role)) {
			throw new Error("role must teacher | student");
		}

		const params = {
			role: role,
			start_date: start_date || undefined,
			end_date: end_date || undefined,
		};

		if (filter) {
			params.filter = 'range_date'
		}

		try {
			return await this.dataRepository.getPresenceSummary(params);
		} catch (error) {
			console.log(error)
		}
	}

	async schoolClassAddStudentArrayInClassPost(payload) {
		if (typeof payload !== 'object') {
			throw new Error("payload not same");
		}

		const { classId, nisn } = payload;
		if (!nisn) {
			throw new Error("payload not same");
		}

		let formData = Array.isArray(payload.formData) ? payload.formData : [{ nisn }];

		try {
			return await this.dataRepository.schoolClassAddStudentArrayInClass(classId, formData);
		} catch (error) {
			console.log(error)
			throw new ServiceError(`Failed to add student to class: ${error.message}`, "ADD_STUDENT_IN_CLASS_ERROR");
		}
	}

	async schoolClassRemoveStudentInClassDelete(classId, studentId) {
		if (!classId || !studentId) {
			throw new Error("request not complete");
		}

		try {
			return await this.dataRepository.schoolClassStudentRemoveInClass(classId, studentId);
		} catch (error) {
			throw new ServiceError(`Failed to remove student from class: ${error.message}`, "REMOVE_STUDENT_IN_CALASS_ERROR");
		}
	}
}
