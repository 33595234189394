import ApiService from "@/api/ApiService";
import axios from "axios";

const PATH_FIRST = "school";
//const MOCK = "http://localhost:3000/api";

class GlobalRepository {
	constructor() {
		this.apiService = ApiService;
		this.cancelTokenSource = null;
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.");
		}
		this.cancelTokenSource = axios.CancelToken.source();
	}

	async getPresenceSummary(params) {
		this.createCancelToken()
		return await this.apiService.get(`/${PATH_FIRST}/dashboard/presence/summary`, { params });
	}

	async schoolClassListStudentByClassId(classId) {
		return await this.apiService.get(`/${PATH_FIRST}/class/${classId}/student`);
	}

	async schoolClassAddStudentArrayInClass(classId, formData) {
		return await this.apiService.post(`/${PATH_FIRST}/class/${classId}/student`, formData);
	}

	async schoolClassListStudentAllByClassId(classId) {
		return await this.apiService.get(`/${PATH_FIRST}/class/${classId}/student/all`);
	}

	async schoolClassById(classId) {
		return await this.apiService.get(`/${PATH_FIRST}/class/${classId}`);
	}

	async schoolClassStudentRemoveInClass(classId, studentId) {
		return await this.apiService.delete(`/${PATH_FIRST}/class/${classId}/student/${studentId}`);
	}
}

export default new GlobalRepository();
